:root{

    /* Variable for admin section*/
    --loginBackground: rgb(40,38,44);
    --loginGradient: linear-gradient(10deg, rgba(40,38,44,1) 34%, rgba(174,44,81,1) 65%);
    --dashboardWidth: 15vw;

    /* Variable for page Title */
    --title_fontSize: 3rem;
    --title_fontFamily: 'Roboto';
    --title_fontStyle: italic;
    --title_fontWeight: 700;
    
    /* Variable for News Slider: SwiperJs */
    --swiper-theme-color: #3E8989;


    /* ----------------------------------------------
 * ----------------------------------------
 * medium display size
 * ----------------------------------------
 */
    --md_title_fontSize: 2.5rem;


 /* ----------------------------------------------
 * ----------------------------------------
 * small display size
 * ----------------------------------------
 */

    --sm_title_fontSize: 2rem;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
